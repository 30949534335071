import React from "react";

const GrabIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      width="48"
      height="48"
    >
      {/* Claw Base */}
      <circle cx="32" cy="16" r="4" fill="currentColor" />
      <line x1="32" y1="20" x2="32" y2="32" stroke="currentColor" />

      {/* Left Claw */}
      <path
        d="M32 32 L20 44 L24 48 L36 36"
        stroke="currentColor"
        fill="none"
      />

      {/* Right Claw */}
      <path
        d="M32 32 L44 44 L40 48 L28 36"
        stroke="currentColor"
        fill="none"
      />

      {/* Bottom Grab Mechanism */}
      <line x1="28" y1="36" x2="36" y2="36" stroke="currentColor" />
      <circle cx="32" cy="38" r="2" fill="currentColor" />
    </svg>
  );
};

export default GrabIcon;
