import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Box, Typography, CircularProgress, Backdrop } from "@mui/material";
import Dialog from '@mui/material/Dialog';

export interface StartCountdownHandle {
  startCountdown: () => void;
  cancelCountdown: () => void;
}

const StartCountdown = forwardRef<StartCountdownHandle>((_, ref) => {
  const [count, setCount] = useState<number>(3);
  const [isCounting, setIsCounting] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    startCountdown: () => {
      setCount(4); // Reset the countdown
      setIsCounting(true);
    },
    cancelCountdown:() =>{
      setCount(4); // Reset the countdown
      setIsCounting(false);
    }
  }));

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isCounting && count > 0) {
      timer = setTimeout(() => {
        setCount((prev) => prev - 1);
      }, 1000);
    } else if (count === 0) {
      setIsCounting(false);
    }
    return () => clearTimeout(timer);
  }, [count, isCounting]);

  const getColor = () => {
    /*
    if (count === 4) return "blue";
    if (count === 3) return "orange";
    if (count === 2) return "red";
    return "green";
    */
    if (count === 3) return "#FF00FF"; // Neon pink
    if (count === 2) return "#00FFFF"; // Vibrant blue
    if (count === 1) return "#FFFF00"; // Bright yellow
    return "#00FF00"; // Bright green

  };

  return (
        <Dialog 
        maxWidth={'xl'}
        sx={{backgroundColor: 'black'}}
        slots={{
          backdrop: Backdrop, // Specify the Backdrop component
        }}
        open = {isCounting}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.96)", // Black overlay
            },
          },

        }}
        PaperProps={{
          sx: {
            backgroundColor: 'black', // Set backdrop background color to black with transparency
          },
        }}

        >
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="300px"
      sx={{backgroudColor:'black', 
        border:0


      }}
      bgcolor= {'black'}
    >
              {isCounting && (

      <Box sx={{border:0}} position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={( (count -1 )/ 3) * 100}
          size={150}
          thickness={5}
          sx={{ color: getColor() }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
        border={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="h3"
            component="div"
            fontWeight="bold"
            color={getColor()}
          >
            {count > 1 ? count -1 : "GO!"}
          </Typography>
        </Box>
      </Box>
              )}
    </Box>
    </Dialog>
  );
});

export default StartCountdown;
