import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress, Box, useMediaQuery, useTheme } from '@mui/material';
import { WebRTCPlayer } from '@eyevinn/webrtc-player';

import LoadingSpinner from './LoadingSpinner';
import PublicIcon from '@mui/icons-material/Public';

interface VideoWithLoadingProps {
  useTopCamera: boolean; // Video source URL
  currentPlayerLocation: string;
}

const VideoWithLoading: React.FC<VideoWithLoadingProps> = ({ useTopCamera, currentPlayerLocation}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isVidPlaying, setIsVidPlaying] = useState<boolean>(false);


  const [loadingText, setLoadingText] = useState('Loading');



  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is mobile

const vidRef = useRef<HTMLVideoElement | null>(null); // TypeScript typing for video element
const vidRefSideCamera = useRef<HTMLVideoElement | null>(null); // TypeScript typing for video element

  // loading
  useEffect(() => {
    // Update loading text to simulate animation
    const interval = setInterval(() => {
      setLoadingText((prev) =>
        prev.length < 10 ? prev + '.' : 'Loading'
      ); // Add dots to simulate movement
    }, 500);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const handleVideoPlay = () => {
    setLoading(false); // Hide spinner when video starts playing
  };

useEffect(() => {
  // Wait for `elementRef` to be assigned to an actual DOM element
  if (vidRef.current && !isVidPlaying) {
      const player = new WebRTCPlayer({
        video: vidRef.current,
        type: 'whep',
        statsTypeFilter: '^candidate-*|^inbound-rtp',
        mediaConstraints: {
          videoOnly:true
        }
      });
      let channelUrl = 'https://clawstreaming.d8superstore.com/rtc/v1/whep/?app=live&stream=top_camera'
      //let channelUrl = 'http://localhost:1985/rtc/v1/whep/?app=live&stream=902'
      player.load(new URL(channelUrl));
        console.log('media recovered');

      // Subscribe for RTC stats: `stats:${RTCStatsType}`
      player.on('stats:inbound-rtp', (report) => {
        if (report.kind === 'video') {
          console.log(report);
         // alert(report)
        }
      });


  }
}, [vidRef.current]); // Dependency array to re-check when ref changes

useEffect(() => {
  // Wait for `elementRef` to be assigned to an actual DOM element
  if (vidRefSideCamera.current ) {

      const player_side = new WebRTCPlayer({
        video: vidRefSideCamera.current,
        type: 'whep',
        statsTypeFilter: '^candidate-*|^inbound-rtp',
        mediaConstraints: {
          videoOnly:true
        }
      });
      let channelUrl_side = 'https://clawstreaming.d8superstore.com/rtc/v1/whep/?app=live&stream=side_camera'
      //let channelUrl = 'http://localhost:1985/rtc/v1/whep/?app=live&stream=902'
      player_side.load(new URL(channelUrl_side));

  }
}, [vidRefSideCamera.current]); // Dependency array to re-check when ref changes


useEffect(() => {
  if( vidRef.current && vidRefSideCamera.current){
  if(useTopCamera){
    vidRef.current.style.display='block'
    vidRefSideCamera.current.style.display='none'


  }else{
    vidRef.current.style.display='none'
    vidRefSideCamera.current.style.display='block'

  }
}

}, [useTopCamera, ]); // Dependency array to re-check when ref changes


  return (
    <Box
      sx={{ 
        ...loading && ({
        position: 'relative',
        width: '100%',
        maxWidth: '800px', // Adjust to fit your layout
        margin: 'auto',
      })}}
    >
      {/* Loading Spinner */}
      {loading && (





        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
<LoadingSpinner/>
        </Box>
      )}
      

{currentPlayerLocation && (
          <div style={{'color':'white', textAlign: 'left', 'marginLeft': '0.5em', zIndex:10, marginTop: '0.3em', position:'absolute',  }}>


<Box display="flex" alignItems="center">
         <PublicIcon style={{marginRight:'0.25em', marginLeft:'0.15em', fontSize:'1em'}}/> {currentPlayerLocation}
    </Box>

          </div>
)}
          <video
          ref={vidRef}
          autoPlay
            onPlay={handleVideoPlay}
          style={{ 
            //...loading &&({display:'none'}),

            //maxHeight: isMobile? "50vh" : "auto", // Full height on desktop
            //maxHeight: '100vh',
            //maxWidth: isMobile? "100vw" : "auto", // Full height on desktop
            height: isMobile? "50vh" : "100%", // Full height on desktop
            maxHeight: isMobile? "100vw" : "auto", // Full height on desktop
            maxWidth: isMobile? "100vw" : "auto", // Full height on desktop

          }}
          muted={true}
    />  
          <video
          ref={vidRefSideCamera}
          autoPlay
            onPlay={handleVideoPlay}
          style={{ 

            //maxHeight: isMobile? "50vh" : "auto", // Full height on desktop
            //maxHeight: '100vh',
            //maxWidth: isMobile? "100vw" : "auto", // Full height on desktop
            height: isMobile? "50vh" : "100%", // Full height on desktop
            maxHeight: isMobile? "100vw" : "auto", // Full height on desktop
            maxWidth: isMobile? "100vw" : "auto", // Full height on desktop
            //...loading &&({display:'none'}),
            display: 'none',

          }}
          muted={true}
    />  
    </Box>
  );
};

export default VideoWithLoading;
