import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingSpinner: React.FC = () => {
  const [loadingText, setLoadingText] = useState('Loading');

  useEffect(() => {
    // Update loading text to simulate animation
    const interval = setInterval(() => {
      setLoadingText((prev) =>
        prev.length < 10 ? prev + '.' : 'Loading'
      ); // Add dots to simulate movement
    }, 500);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Full-screen height
      }}
    >
      {/* Circular Loader */}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '150px',
          height: '150px',
        }}
      >
        <CircularProgress
          size={100}
          thickness={4}
          sx={{
            //color: '#ff6f00', // Custom claw machine orange
            //color: 'rgb(255, 193, 7)',
            color: '#FFC107',
            animation: 'spin 1.5s linear infinite', // Smooth rotation effect
          }}
        />
        {/* Inner Element: Simulates a claw or ball */}
        <Box
          sx={{
            position: 'absolute',
            width: '50px',
            height: '50px',
            //backgroundColor: '#ff6f00',
            backgroundColor: '#FFC107',
            //backgroundColor: 'rgb(255, 193, 7)',
            borderRadius: '50%',
            animation: 'bounce 1s infinite',
          }}
        />
      </Box>

      {/* Loading Text */}
      <Typography
        variant="h6"
        sx={{
          marginTop: '16px',
          fontWeight: 'bold',
          color: 'white',
        }}
      >
        {loadingText}
      </Typography>

      {/* Keyframe Animations */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-20px); }
          }
        `}
      </style>
    </Box>
  );
};

export default LoadingSpinner;
