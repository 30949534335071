import React, { useEffect } from "react";

type SoundPlayerProps = {
  playSound: boolean; // Trigger to play the sound
  soundUrl: string; // URL of the sound to play
  isAudioEnabled: boolean;
  onPlayComplete?: () => void; // Optional callback when sound finishes
};

const SoundPlayer: React.FC<SoundPlayerProps> = ({ playSound, soundUrl, isAudioEnabled, onPlayComplete }) => {
  useEffect(() => {
    if (playSound && isAudioEnabled) {
      const audio = new Audio(soundUrl);

      audio.play()
        .then(() => {
          if (onPlayComplete) {
            onPlayComplete();
          }
        })
        .catch((error) => {
          console.error("Error playing sound:", error);
        });

      // Cleanup the audio instance
      return () => {
        audio.pause();
        audio.currentTime = 0;
      };
    }
  }, [playSound, soundUrl, onPlayComplete]);

  return null; // No UI for this component
};

export default SoundPlayer;
