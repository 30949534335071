import React, { useEffect, useState } from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';

// Define the props type
interface QueueBannerProps {
  position: number; // Current queue position
  total: number; // Total number of people in the queue
}

const QueueBanner: React.FC<QueueBannerProps> = ({ position, total }) => {
  // Optional: Use this state to manage animations or display changes
  const [currentPosition, setCurrentPosition] = useState(position);
  const [currentTotal, setCurrentTotal] = useState(total);
  const [progress, setProgress] = useState(0);


  // Watch for changes in the position prop
  useEffect(() => {
    if (position !== currentPosition) {
      setCurrentPosition(position);
      console.log(`Queue position updated: ${position}`);
    }
    if (total !== currentTotal) {
      setCurrentTotal(total);
      console.log(`Total queue size updated: ${total}`);
    }
  }, [position, total, currentPosition, currentTotal]);

    // Update progress dynamically when position or total changes
    useEffect(() => {
        if (currentTotal > 0) {
          const calculatedProgress = ((currentTotal - currentPosition + 1) / currentTotal) * 100;
          setProgress(calculatedProgress);
        }
      }, [currentPosition, currentTotal]);

  // Only show the banner if the queue exists and `total` is greater than 0
  if (currentPosition  ==0) {
    return null;
  }

    

  return (
    <Box
      sx={{
        padding: '16px',
        backgroundColor: '#f5f5f5',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        textAlign: 'left'
      }}
    >
      {/* Left Section: Queue Info */}
      <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
        Oh, shucks! Other people are playing.
        <br/><br/>
        Your queue position: {currentPosition}
        <br/><br/>

        Please hold tight and the game will automatically start when it's your turn.
      </Typography>

      {/* Right Section: Progress Bar */}
      <Box sx={{ flexGrow: 1, marginLeft: '16px' }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: '10px',
            borderRadius: '5px',
            display: 'none'
          }}
        />
      </Box>
    </Box>
  );
};

export default QueueBanner;
