import React, { useEffect, useRef } from "react";

interface ClawMovingSoundEffectProps {
  isPlayingClawMovingSoundEffect: boolean;
  isAudioEnabled: boolean
}

const ClawMovingSoundEffect: React.FC<ClawMovingSoundEffectProps> = ({
  isPlayingClawMovingSoundEffect,
  isAudioEnabled
}) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioRef.current && isAudioEnabled) {
      if (isPlayingClawMovingSoundEffect) {
        audioRef.current.play(); // Play the sound effect
        console.log('play sound effect')
      } else {
        audioRef.current.pause(); // Pause the sound effect
        console.log('stop sound effect')
      }
    }
  }, [isPlayingClawMovingSoundEffect]); // React to changes in the isPlaying prop

  return (
    <audio ref={audioRef} src="music/coffee.mp3" loop hidden />
  );
};

export default ClawMovingSoundEffect


