import React from 'react';
import { Box } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const LightbulbThinking: React.FC = () => {
  return (
    <Box
    sx={{ position: 'absolute',
        top:'4.5em',
        right:'130px',
    }}
    >
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(25,33,38,0.8)' ,
        borderRadius: '1em'
      }}
    >
      {/* Lightbulb Icon */}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '120px',
          height: '120px',
          animation: 'pulse 1.5s infinite',
        }}
      >
        <LightbulbIcon
          sx={{
            fontSize: '60px',
            color: '#FFD700', // Bright yellow for the lightbulb
            animation: 'glow 1.5s infinite',
          }}
        />
      </Box>

      {/* Thinking Text */}
      <Box
        sx={{
          marginTop: '-1em',
          marginBottom: '1.5em',
          fontSize: '16px',
          fontWeight: 'bold',
          //color: '#555',
          color: 'rgb(230, 230, 230)',
          animation: 'fade 1.5s infinite',
        }}
      >
        Thinking...
      </Box>

      {/* Keyframe Animations */}
      <style>
        {`
          @keyframes pulse {
            0%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1);
            }
          }
          @keyframes glow {
            0%, 100% {
              filter: drop-shadow(0 0 5px rgba(255, 215, 0, 0.5));
            }
            50% {
              filter: drop-shadow(0 0 20px rgba(255, 215, 0, 1));
            }
          }
          @keyframes fade {
            0%, 100% {
              opacity: 1;
            }
            50% {
              opacity: 0.5;
            }
          }
        `}
      </style>
    </Box>
    </Box>
  );
};

export default LightbulbThinking;
